@import '../../shared/styles/variables';

.sidebar {
  min-width: 300px;
  max-width: 400px;
  margin: 15px;
  padding: 40px;
  background-color: $pink-background;
  border-radius: 5px;
}

@media (max-width: 1300px) {
  .sidebar {
    max-width: 80%;
    margin: 25px auto;
  }
}
