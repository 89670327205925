.spinner_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinner {
  width: 25px;
  height: auto;
}
