@import './shared/styles/variables';
@import './shared/styles/typography';

// styles for react-tabs
@import '../node_modules/react-tabs/style/react-tabs.scss';

// styles for react-toastify
@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $text-color;
  font-size: $font-size;
  font-family: $font-family-sans-serif;
  line-height: 1.5;
}

.main {
  min-height: calc(100vh - 210px);
  margin: 0 40px;
  padding: 30px;
}

// Richtext styles (for welcome text, account resources and program resources
.richtext {
  a {
    color: $black;
    text-decoration: underline;
  }

  br {
    display: none;
  }
}

// Overwrite React Tabs styles
.react-tabs {
  width: 80%;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.react-tabs__tab-panel--selected {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

// Overwrite React Quill styles
.ql-toolbar.ql-snow {
  border-radius: 10px 10px 0 0;
  border: 1px solid $grey-dark;
}

.ql-container.ql.snow {
  border: 1px solid $grey-dark;
}

.ql-container {
  font-family: $font-family-sans-serif;
  color: $text-color;
  font-size: $font-size;
  height: auto;
  border-radius: 0 0 10px 10px;
  line-height: 2;
  padding: 20px;
}

// Global Admin styles
.admin_list_page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin_form_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    padding: 0;
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 15px;
  }

  .admin_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    width: 60%;
  }

  .submit_container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
}
