@import '../../shared/styles/variables';

@mixin padding {
  padding: 10px 25px 8px;
}

a {
  font-family: $font-family-sans-serif;
  font-size: $button-font-size;
  border-radius: 50px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  text-decoration: none;
}

.primary {
  @include padding;
  background: $button-colour;
  border: none;
  color: $white;

  &:hover {
    background: $red-alt;
  }
}

.secondary {
  @include padding;
  background: $orange;
  border: none;
  color: $white;
}

.outline {
  @include padding;
  background: $white;
  border: 1px solid $button-colour;
  color: $button-colour;
  text-transform: uppercase;
}

.nav_link {
  color: $white;
  font-size: 1.3rem;
}

.text_link {
  color: $orange;
}

.footer_link {
  font-size: 14px;
  color: $white;
}
