@import '../../shared/styles/variables';

.program_card {
  margin: 30px auto;
  display: flex;

  img {
    margin: 20px;
    width: 100%;
    height: auto;
    max-width: 150px;
    max-height: 150px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .details {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .container {
      display: flex;
      gap: 20px;

      .icon_group {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 25px;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .details {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
