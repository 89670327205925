@import '../../shared/styles/variables';

button {
  font-family: $font-family-sans-serif;
  font-size: $button-font-size;
  border-radius: 50px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  padding: 10px 25px 8px;

  &:disabled {
    background: $grey-light;
    color: $grey-dark;
  }
}

.primary {
  background: $button-colour;
  border: none;
  color: $white;

  &:hover:enabled {
    background: $red-alt;
  }
}

.secondary {
  background: $orange;
  border: none;
  color: $white;

  &:hover:enabled {
    background: $orange-light;
  }
}

.cancel {
  background: $grey-dark;
  border: none;
  color: $white;
}

.outline {
  background: $white;
  border: 1px solid $button-colour;
  color: $button-colour;
  text-transform: uppercase;
}

.nav_link {
  color: $white;
  font-size: 1.3rem;
  background: transparent;
  border: none;
  padding: 0;
}
