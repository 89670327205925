@import '../../shared/styles/variables';

.profile {
  display: flex;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
      border: 1px solid $grey-dark;
      border-radius: 10px;
      padding: 30px 100px;
      margin-bottom: 30px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    width: 60%;

    h3 {
      padding-bottom: 0;
    }

    .submit_container {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 1300px) {
  .profile {
    flex-direction: column;
  }
}
