@import '../../../shared/styles/variables';

.packages_table {
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    align-self: flex-end;
  }
}

.reorder_buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;

  button {
    font-size: 25px;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px 10px;
    border: none;
    background: transparent;
  }
}
