@import '../../shared/styles/variables';

.navbar {
  background-image: $gradient;
  color: $white;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .site_name {
    font-family: $font-family-serif;
    color: $white;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    margin: 10px 0;
    padding-bottom: 0;

    img {
      width: 90px;
      height: auto;
    }
  }

  .links {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}
