@import '../../../shared/styles/variables';

.notification_form {
  margin: 50px auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .confirmation {
    margin-top: 30px;
    border: 1px solid $grey-dark;
    border-radius: 10px;
    padding: 30px 100px;
    text-align: center;

    .confirmation_buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
}
