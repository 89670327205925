@import '../../shared/styles/variables';

.footer {
  background-image: $gradient;
  color: $white;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
