@import '../../shared/styles/variables';

.reset_password_page {
  border: 1px solid $grey-dark;
  border-radius: 10px;
  padding: 30px 100px;
  margin: 30px auto;
  width: 80%;

  h1,
  h2,
  h4,
  p {
    text-align: center;
  }

  .link_container {
    display: flex;
    justify-content: center;
  }
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  width: 60%;
  margin: 0 auto;

  h3 {
    padding-bottom: 0;
  }

  .submit_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
  }
}
