@import '../../shared/styles/variables';

.login {
  display: flex;
  height: 100vh;

  .product_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: $pink-background;

    .container {
      display: flex;
      flex-direction: column;
      width: 70%;

      img {
        display: block;
        margin: 0 auto;
        max-width: 325px;
        width: 100%;
        height: auto;
      }

      .site_name {
        font-family: $font-family-serif;
        color: $primary;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
        margin: 15px 0;
      }

      a {
        align-self: flex-start;
      }
    }
  }

  .login_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: $white;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 25px;
      width: 60%;

      h3 {
        padding-bottom: 0;
      }

      .submit_container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 30px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .login {
    flex-direction: column;

    .product_info {
      width: 100%;
      padding: 80px 25px;
    }

    .login_form {
      width: 100%;
      margin-top: 60px;
    }
  }
}
