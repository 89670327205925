@import '../../shared/styles/variables';

.program_page {
  display: flex;

  .content {
    margin-right: 50px;

    img {
      margin: 15px;
      width: 100%;
      height: auto;
      max-width: 150px;
      max-height: 150px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .program_info {
      display: flex;
      gap: 40px;

      .left_sidebar {
        margin: 0 15px;
        width: 650px;
      }
    }

    .details {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .container {
        display: flex;
        gap: 20px;

        .icon_group {
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            width: 25px;
            height: auto;
          }
        }
      }
    }

    .download_section {
      margin: 40px 15px 0 15px;
      display: flex;
      gap: 40px;

      .select_dropdowns {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      div:last-child {
        flex-grow: 1;
      }

      table th {
        background-color: $primary;
        color: $white;
      }

      input[type='checkbox'] {
        cursor: pointer;
        width: 1.5em;
        height: 1.5em;
      }
    }

    .download_button {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 1400px) {
  .program_page {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .download_section {
    flex-direction: column;
  }
}
