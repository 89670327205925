@import '../../shared/styles/variables';

.input_container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 5px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2em;
  color: $grey-dark;
}

.input {
  padding: 10px;
  margin-top: 5px;
  border-radius: 10px;
  line-height: 2;
  border: 1px solid $grey-dark;
  color: $text-color;
  font-size: $font-size;
  font-family: $font-family-sans-serif;

  &:focus {
    box-shadow: 0 0 10px $primary;
    outline: 0;
  }
}

.file_input {
  font-size: $font-size;
  font-family: $font-family-sans-serif;
  padding: 10px;
  border: 1px solid $black;
  border-radius: 10px;

  &::file-selector-button {
    background: $white;
    border-radius: 50px;
    border: 2px solid $orange;
    font-weight: 600;
    font-size: $button-font-size;
    color: $orange;
    line-height: 1;
    cursor: pointer;
    padding: 10px 20px;
    margin: 5px 15px;

    &:hover {
      border-color: $orange-light;
      color: $orange-light;
    }
  }
}

.checkbox_input_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .checkbox_label {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.2em;
    color: $grey-dark;
  }

  input[type='checkbox'] {
    cursor: pointer;
    width: 1.25em;
    height: 1.25em;
  }
}

.form_error_message {
  font-style: italic;
  font-size: 14px;
  color: $red;
  margin: 5px 0 0 0;
}
