@import '../../shared/styles/variables';

.table {
  border-spacing: 0;
  border: 1px solid #ededed;
  width: 100%;
  margin: 0 auto;

  tr:last-child td {
    border-bottom: 0;
  }

  th {
    cursor: pointer;

    svg {
      margin-left: 5px;
    }
  }

  th,
  td {
    margin: 0;
    padding: 1rem 2rem;
    border-bottom: 1px solid $grey-light;
    border-right: 1px solid $grey-light;
    position: relative;
  }
  th:last-child,
  td:last-child {
    border-right: 0;
  }
  tr:nth-child(even) {
    background-color: $grey-lighter;
  }

  tr td:last-child {
    width: 1%;
  }
}

.table_with_delete {
  tr td:nth-last-child(2) {
    width: 1%;
  }
}

.search {
  svg {
    position: relative;
    left: 44px;
    top: 3px;
  }

  .filter_input {
    padding: 5px 5px 5px 35px;
    margin: 15px;
    border-radius: 10px;
    line-height: 2;
    border: 1px solid $grey-dark;
    color: $text-color;
    font-size: 14px;
    font-family: $font-family-sans-serif;

    &:focus {
      box-shadow: 0 0 3px $grey-darker;
      outline: 0;
    }
  }
}

.pagination_buttons {
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .page_count {
    margin: 0 15px;
    display: flex;
    gap: 5px;
  }
}
