@import '../../shared/styles/variables';

.account_page {
  display: flex;

  .content {
    margin-right: 50px;

    h1 {
      padding-bottom: 0;
    }

    .inactive_programs_title {
      margin-top: 90px;
    }
  }
}

@media (max-width: 1300px) {
  .account_page {
    flex-direction: column;
    align-items: center;
  }
}
