@import '../../shared/styles/variables';

.tos_page {
  border: 1px solid $grey-dark;
  border-radius: 10px;
  padding: 30px 100px;
  margin: 30px auto;
  width: 80%;

  h1,
  h2 {
    text-align: center;
  }

  .email_link {
    color: $black;
  }
}

.submit_container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
