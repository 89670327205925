@import './bootstrap-variables';

$font-size: $font-size-base;
$button-font-size: $font-size-nominal-default;
$button-colour: #e51546;
// colours
$white: #ffffff;
$black: #404040;
$red: #fc2f52;
$red-alt: #e92b57;
$red-light: #feb9bb;
$red-dark: #9c0061;
$pink: #fcf2f5;
$pink-background: #fff2f5;
$purple: #8c2574;
$purple-light: #f1e9f0;
$link-hover: #8f0074;
$grey: #f2f2f2;
$grey-dark: #7a7a7a;
$grey-darker: #54595f;
$grey-light: #eee;
$grey-lighter: #fafafa;
$grey-form: #3d3d3d;
$grey-slider: #e3e3e4;
$orange: #ec6018;
$orange-light: #f37021;
$footnote-text: #9aaaa9;
$mobile-nav: #f7f7f7;
$dropdown-item-hover: #55595c;
$dotted-border: #c9c9c9;
$table-border: #dee2e6;

$success: #f2fcf2;
$green-darker: #002004;
$warning: #ffae6b;

$input-border: rgba(0, 0, 0, 0.2);
$input-error-background: #ff6666;

$gradient: linear-gradient(90deg, $red 0%, $orange-light 100%);
