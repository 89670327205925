@import '../../../shared/styles/variables';

.package_file_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.language_header {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.file_listing {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;

  td {
    border-spacing: 0;
    border: 1px solid #ededed;
    padding: 15px;
    text-align: center;
  }

  button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    color: $grey-form;
  }
}
